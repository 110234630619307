import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

import Text from './shared/Text'
import Button from './shared/Button'
import Image from './shared/Image'
import Link from './shared/Link'
import { UList, ListItem } from './shared/List'
import { Heading, Paragraph } from './shared/Typography'
import { ContentContainer, SectionContainer } from './shared/Containers'

import ExpLeftSideBar from '../images/ExpLeftSideBar.svg'
import ExpRightSideBar from '../images/ExpRightSideBar.svg'

import BrixioLogo from '../images/logos/brixio.png'
import RavnLogo from '../images/logos/ravn.png'
import GatsbyLogo from '../images/logos/gatsby.png'
import ByuLogo from '../images/logos/byu.png'

import SAPLogo from '../images/logos/SAPLogo.png'
import JunctionLogo from '../images/logos/JunctionLogo.png'
import STLogo from '../images/logos/STLogo.png'
import RGALogo from '../images/logos/RGALogo.png'
import MagesLogo from '../images/logos/mages.png'
import SutdLogo from '../images/logos/SutdLogo.png'

import {
  COLORS,
  MEDIA_QUERIES,
  BORDER_RADIUS,
  TRANSITION,
} from '../styles/constants'

const Intro = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const IntroDescription = styled.div`
  margin-bottom: 25px;
`
const WorkContainer = styled.div`
  display: grid;
  grid-gap: 45px;
  grid-template-areas: 'companies info';
  grid-template-columns: 1fr 3fr;
  @media (max-width: ${MEDIA_QUERIES['mobile']}px) {
    grid-template-areas: 'companies' 'info';
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
`
const WorkButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: ${MEDIA_QUERIES['mobile']}px) {
    flex-direction: row;
    overflow-x: scroll;
    border-bottom: 1px ${COLORS.lightWhite} solid;
    padding: 10px 5px;
  }
`
const Dot = styled.div`
  opacity: 0.5;
  transition: ${TRANSITION};
  display: inline-block;
  margin-right: 10px;
  vertical-align: center;
  height: 8px;
  width: 8px;
  background-color: ${[
    props => (props.active ? COLORS.gold : COLORS.lightGray),
  ]};
  border-radius: 50%;
`
const WorkButton = styled(Button)`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: ${BORDER_RADIUS.button};
  transition: ${TRANSITION};
  padding: 10px 30px;
  border: none;
  color: ${COLORS.lightGray};
  &:hover {
    background-color: ${COLORS.semiWhite};
    color: ${COLORS.white};
  }
  &:focus {
    color: ${COLORS.gold};
  }
  @media (max-width: ${MEDIA_QUERIES['mobile']}px) {
    margin-bottom: 0px;
    margin-right: 10px;
  }
`
const WorkDetails = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-rows: auto auto 4fr;
  align-items: flex-start;
`
const RoundedImageContainer = styled.div`
  display: inline-block;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  background-color: ${COLORS.lightWhite};
  padding: 5px;
  margin-right: 15px;
`

const jobs = [
  {
    company: 'SAP',
    logo: SAPLogo,
    jobTitle: 'Product Management Intern',
    dates: 'May 2020 - Sep 2020',
    bullets: [
      <Text>
        Worked under SAP AI Business Services team on AI products -{' '}
        <Link
          href="https://blogs.sap.com/2020/05/29/how-covid-19-is-changing-everyones-behaviour-and-how-businesses-can-react/#:~:text=Recommendation%20Service%20is%20natively%20embedded,respective%20license%20in%20each%20solution."
          target="_blank"
        >
          Recommendation Service
        </Link>{' '}
      </Text>,
      `Collaborated with different teams - Developers, Data Scientists, Product Managers, Product Owners`,
      <Text>
        Assisted in development of a{' '}
        <Link
          href="https://www.youtube.com/watch?v=0VLEhGbgqRg"
          target="_blank"
        >
          web application
        </Link>{' '}
        to demonstrate the SAP Recommendation Service. Utilised React.js, Flask,
        Docker. Webscraped data with Python, Selenium, BeautifulSoup
      </Text>,
    ],
  },
  {
    company: 'Mages Institute',
    logo: MagesLogo,
    jobTitle: 'Full Stack Teaching Assistant',
    dates: 'Feb 2020 - Mar 2020',
    bullets: [
      <Text>
        Teaching Assistant for a {' '}
        <Link
          href="https://mages.edu.sg/academics/programmes/tech-immersion/full-stack-web-development/"
          target="_blank"
        >
          Full Stack Web Development Bootcamp
        </Link>{' '}
      </Text>,
      `Mentored 2 teams for their final capstone web app projects`,
      `Suggested tech architectures and assisted with implementation, covering tools like React, Redux, Django, SQL databases, Firebase, JavaScript, Python`
    ],
  },
  {
    company: 'SUTD',
    logo: SutdLogo,
    jobTitle: 'Teaching Assistant',
    dates: 'Jan 2020 - May 2020',
    bullets: [
      <Text>
        Teaching Assistant for {' '}
        <Link
          href="https://istd.sutd.edu.sg/undergraduate/courses/50003-elements-of-software-construction"
          target="_blank"
        >
          50.003 Elements of Software Construction
        </Link>{' '}
      </Text>,
    ],
  },
  {
    company: 'JunctionX',
    logo: JunctionLogo,
    jobTitle: 'Head of Partnerships, Web Developer',
    dates: 'Dec 2019 - Present',
    bullets: [
      <Text>
        Co-organised{' '}
        <Link target="_blank" href="https://asia.hackjunction.com/">
          JunctionX Asia
        </Link>{' '}
        (held in June 2020), a student-run virtual hackathon for developers and
        technology enthusiasts, with JunctionX teams from Hanoi, Seoul and
        Chengdu.
      </Text>,
      `Engaged with external partners for the hackathon and successfully secured Microsoft as one of the partners and challenge owners`,
      <Text>
        Developed websites for{' '}
        <Link target="_blank" href="https://singapore.hackjunction.com/">
          JunctionX Singapore
        </Link>{' '}
        and
        <Link target="_blank" href="https://asia.hackjunction.com/">
          JunctionX Asia
        </Link>{' '}
        utilising React.js, Firebase, Flamelink with web dev team
      </Text>,
    ],
  },
  {
    company: 'ST Engineering',
    logo: STLogo,
    jobTitle: 'Data Analyst Intern',
    dates: 'Oct 2019 - Jan 2020',
    bullets: [
      'Project internship with ST Engineering, ST Electronics (Info-Software Systems), Data Analytics Business Unit',
      'Under SUTD-MIT International Design Centre (IDC) Design Odyssey Enterprise Innovation Programme',
      'Analysed commercial flight data to produce valuable insights on flight operations and possibilities of optimisation',
      'Created a Performance Framework for Aircraft-Collaborative Decision Making (A-CDM)',
    ],
  },
  {
    company: 'R/GA',
    logo: RGALogo,
    jobTitle: 'Software Engineer Intern',
    dates: 'May 2019 - Sep 2019',
    bullets: [
      'Assisted in development of a large-scale web application with React - Redux framework for UOB platform',
      'Created a JavaScript script instrumental in validating proper development across files',
      'Developed Unit Tests with Jests in preparation for integration of frontend and backend team',
      'Built a PDF renderer to generate pdf reports from React application for client, using Puppeteer and chrome headless',
    ],
  },
]

const Experience = () => {
  const [selectedIndex, setIndex] = React.useState(null)

  const onSelectJob = selectedIndex => setIndex(selectedIndex)

  const selectedJob = jobs[selectedIndex] || jobs[0]
  return (
    <SectionContainer id="experience" backgroundFilled>
      <Image
        src={ExpLeftSideBar}
        sx={{
          position: 'absolute',
          left: 0,
          transform: 'translateY(-50px)',
        }}
        lighten
      />
      <Image
        src={ExpRightSideBar}
        sx={{
          position: 'absolute',
          right: 0,
          transform: 'translateY(-50px)',
        }}
        lighten
      />
      <ContentContainer style={{ gridGap: 30 }}>
        <Intro>
          <Heading inverted>Experience</Heading>
          <IntroDescription>
            <Paragraph size="xlarge" weight="bold" color="lightWhite" inverted>
              Here are a few places I've worked
            </Paragraph>
          </IntroDescription>
        </Intro>
        <WorkContainer>
          <WorkButtons>
            {jobs.map((job, index) => (
              <WorkButton onClick={() => onSelectJob(index)}>
                <Dot active={index === selectedIndex} />
                {job.company}
              </WorkButton>
            ))}
          </WorkButtons>
          <WorkDetails>
            <div style={{ padding: '5px 0' }}>
              <Text color="white" size="xxlarge" weight="bold">
                {selectedJob.jobTitle}{' '}
              </Text>
              <Text color="gold" size="xxlarge" weight="bold">
                @ {selectedJob.company}
              </Text>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <RoundedImageContainer>
                <img
                  style={{ widght: 35, height: 35, borderRadius: 5 }}
                  src={selectedJob.logo}
                  alt=":("
                />
              </RoundedImageContainer>
              <Text color="lightWhite" size="medium">
                {selectedJob.dates}
              </Text>
            </div>
            <div>
              <Text color="lightWhite" size="medium">
                <UList columns={1} style={{ color: COLORS.white }}>
                  {selectedJob.bullets.map(bullet => (
                    <ListItem color="white">{bullet}</ListItem>
                  ))}
                </UList>
              </Text>
            </div>
          </WorkDetails>
        </WorkContainer>
      </ContentContainer>
    </SectionContainer>
  )
}

export default Experience
