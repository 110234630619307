import React from 'react'
/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'
//import { StaticQuery, graphql, Link } from 'gatsby'
import { Link } from 'gatsby'

import Button from './shared/Button'
import Image from './shared/Image'
import { Heading, Paragraph } from './shared/Typography'
import {
  ContentContainer,
  SectionContainer,
  CardGrid,
} from './shared/Containers'
import BlogCard from './BlogCard'

import ExpLeftSideBar from '../images/ExpLeftSideBar.svg'
import ExpRightSideBar from '../images/ExpRightSideBar.svg'

const Intro = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const IntroDescription = styled.div`
  margin-bottom: 25px;
`

const Learning = () => {
  return (
    <SectionContainer id="learning" backgroundFilled>
      <Image
        src={ExpLeftSideBar}
        style={{
          position: 'absolute',
          left: 0,
          transform: 'translateY(-50px)',
        }}
        lighten
      />
      <Image
        src={ExpRightSideBar}
        style={{
          position: 'absolute',
          right: 0,
          transform: 'translateY(-50px)',
        }}
        lighten
      />
      <ContentContainer style={{ gridGap: 30 }}>
        <Intro>
          <Heading inverted>Learning</Heading>
          <IntroDescription>
            <Paragraph size="xlarge" weight="bold" color="lightWhite" inverted>
              I blog about things I've learned and love on Medium.
            </Paragraph>
          </IntroDescription>
        </Intro>
        <CardGrid mediaSize="desktop">
          {/* <StaticQuery
            query={graphql`
              query MyBlogPosts {
                allMdxBlogPost(limit: 4, sort: { fields: date, order: DESC }) {
                  nodes {
                    id
                    date
                    slug
                    title
                    parent {
                      ... on Mdx {
                        frontmatter {
                          image
                          description
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ allMdxBlogPost: { nodes } }) => (
              <>
                {nodes.map(
                  ({
                    date,
                    slug,
                    title,
                    parent: {
                      frontmatter: { image: imageUrl, description: subtitle },
                    },
                  }) => (
                    <BlogCard
                      date={date}
                      title={title}
                      subtitle={subtitle}
                      claps={'<50'}
                      imageUrl={imageUrl}
                      link={slug}
                    />
                  )
                )}
              </>
            )}
          /> */}
          <BlogCard
            date="May 13 2020"
            title="What is a PWA? Why PWAs?"
            subtitle="All about Progressive Web Applications"
            claps={'<50'}
            imageUrl="https://miro.medium.com/max/512/1*EkMdtpOHz438qE94MLOTBQ.png"
            // link="https://medium.com/swlh/what-is-a-pwa-why-pwas-d53e592c7dbe"
            link="https://medium.com/swlh/what-is-a-pwa-why-pwas-d53e592c7dbe?sk=941897bc16a5d5300d740fb2e8e32408" // friend link
          />
          <BlogCard
            date="Aug 1 2020"
            title="Master useState & useEffect React Hooks API"
            subtitle="An explanation of the common ways to use useState & useEffect Hooks API"
            claps={'<50'}
            imageUrl="https://miro.medium.com/max/700/1*5HL3lgULpBtMEHMEZ5BR0Q.png"
            // link="https://codeburst.io/master-usestate-useeffect-react-hooks-api-103239f79672e"
            link="https://codeburst.io/master-usestate-useeffect-react-hooks-api-103239f79672?sk=b38f1b960e2d9b2b1a53b7b370deec5e" // friend link
          />
          <BlogCard
            date="Jul 30 2020"
            title="One of the first things to understand in JavaScript — Immutability"
            subtitle="Mutation in JavaScript and why this is important to know"
            claps={'<50'}
            imageUrl="https://miro.medium.com/max/512/1*4PrMNL-FF9Z5G5BXJliAYg.png"
            // link="https://medium.com/javascript-in-plain-english/one-of-the-first-things-to-understand-in-javascript-immutability-629fabdf4fee"
            link="https://medium.com/javascript-in-plain-english/one-of-the-first-things-to-understand-in-javascript-immutability-629fabdf4fee?sk=8c0ef7426b7f26d07b54b6b6dc382617" // friend link
          />
          <BlogCard
            date="Jan 13 2021"
            title="Speed Up Your Web Scraping Projects With Concurrency"
            subtitle="Use multi-threading in web scraping to instantly speed up your web scraping tasks"
            claps={'<50'}
            imageUrl="https://miro.medium.com/max/700/1*cVYVvjiDPfqH_nP5bONK6g.png"
            // link="https://betterprogramming.pub/speed-up-your-web-scraping-projects-with-concurrency-89f548422100"
            link="https://betterprogramming.pub/speed-up-your-web-scraping-projects-with-concurrency-89f548422100?sk=9184a3ef049fa25f837ba7739a5d96a8" // friend link
          />
          {/* <BlogCard
            date="Nov 24 2019"
            title="RecyclerView & CardView not working properly? — Android App"
            subtitle="Dependency problems in 2020"
            claps={'<50'}
            link="https://medium.com/@daryllwong/recyclerview-cardview-is-not-working-properly-dependency-problems-in-2019-e813ab48b3d4"
          /> */}
        </CardGrid>
        <div sx={{ display: 'flex', justifyContent: 'center' }}>
          <Link to="https://medium.com/@daryllwong">
            <Button>See all posts</Button>
          </Link>
        </div>
      </ContentContainer>
    </SectionContainer>
  )
}

export default Learning
