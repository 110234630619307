/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

import Image from './shared/Image'
import { Heading, Paragraph } from './shared/Typography'
import {
    SectionContainer,
    ContentContainer,
    CardGrid,
} from './shared/Containers'
import ProjectCard from './ProjectCard'

import WorkRightSideBarSVG from '../images/WorkRightSideBar.svg'
import WorkLeftSideBarSVG from '../images/WorkLeftSideBar.svg'

import CardSurgeIcon from '../images/CardSurgeIcon.svg'
import SyntheticIcon from '../images/SyntheticIcon.svg'
import MoreIcon from '../images/MoreIcon.svg'
import SolJournalIcon from '../images/SolJournalIcon.svg'

const Description = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const Hero = () => ( <
SectionContainer id = "projects" >
    <
    Image src = { WorkLeftSideBarSVG }
    sx = {
        { position: 'absolute', left: 0 } }
    lighten /
    >
    <
    Image src = { WorkRightSideBarSVG }
    sx = {
        { position: 'absolute', right: 0 } }
    lighten /
    >
    <
    ContentContainer style = {
        { gridGap: 30 } }
    numColumns = { 1 } >
    <
    Description >
    <
    Heading > Projects < /Heading> <
    Paragraph size = "xlarge"
    weight = "bold" >
    Some things I 've built <
    /Paragraph> <
    /Description> <
    CardGrid >
    <
    ProjectCard icon = { SolJournalIcon }
    title = "Aurient Mentorship Platform"
    desc = "Platform for Talent Managers to manage mentorship programme"
    link = "https://aurient.io/"
    repoName = "aurient" /
    >
    <
    ProjectCard icon = { CardSurgeIcon }
    title = "JunctionX Asia"
    desc = "Website for JunctionX Asia - hackathon for developers in asia"
    link = "https://asia.hackjunction.com/"
    repoName = "junctionx asia" /
    >
    <
    ProjectCard icon = { SyntheticIcon }
    title = "Dementia Social Platform"
    desc = "A PWA developed for dementia caregivers"
    link = "https://github.com/daryllman/dementia-pwa"
    repoName = "dementia-pwa" /
    >
    <
    ProjectCard icon = { MoreIcon }
    title = "More"
    desc = "Other projects on my Github"
    link = "https://github.com/daryllman" /
    >
    <
    /CardGrid> <
    /ContentContainer> <
    /SectionContainer>
)

export default Hero