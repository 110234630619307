/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'

import Image from './shared/Image'
import GatsbyImage from './shared/GatsbyImage'
import { UList, ListItem } from './shared/List'
import {
  ContentContainer,
  SectionContainer,
  CenteredContainer,
} from './shared/Containers'

import AboutMeLeftSideBar from '../images/AboutMeLeftSideBar.svg'
import AboutMeRightSideBar from '../images/AboutMeRightSideBar.svg'

import { Heading, Paragraph } from './shared/Typography'
import { COLORS } from '../styles/constants'

const AboutMeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`
const AboutDescription = styled.div``

const About = () => (
  <SectionContainer id="about">
    <Image
      src={AboutMeLeftSideBar}
      sx={{ position: 'absolute', left: 0, transform: 'translateY(-50px)' }}
      lighten
    />
    <Image
      src={AboutMeRightSideBar}
      sx={{ position: 'absolute', right: 0, transform: 'translateY(-50px)' }}
      lighten
    />
    <ContentContainer numColumns={2}>
      <AboutMeContainer>
        <Heading>About Me</Heading>
        <AboutDescription>
          <Paragraph>
            Hello! 😊 I aspire to create impact by building products that users
            love! I enjoy going through the full journey of conceptualising,
            designing and building products! Always explore, always learning!
          </Paragraph>
          <Paragraph>
            I strongly believe in utilising technology coupled with design
            thinking to tackle complex problems and challenges, with attention
            to detail in code and software architecture.
          </Paragraph>
          <Paragraph>
            Currently a final year student studying Computer Science in SUTD
            with minor in Artificial Intelligence and humanities minor in
            Design, Technology and Society (DTS).
          </Paragraph>
          {/* <Paragraph>
            Embarking on a concurrent masters programme, where I will be
            researching on Serendipity & User-Controlled Recommender Systems
          </Paragraph> */}

          <Paragraph>Here are some tech I've experience with:</Paragraph>
          <UList style={{ color: COLORS.black }}>
            <ListItem>Python</ListItem>
            <ListItem>Javascript</ListItem>
            <ListItem>Java</ListItem>
            <ListItem>React</ListItem>
            <ListItem>Node</ListItem>
            <ListItem>HTML, CSS, JS</ListItem>
            <ListItem>Flask, FastAPI</ListItem>
            <ListItem>Flutter, Dart</ListItem>
            <ListItem>Docker</ListItem>
            <ListItem>SQL</ListItem>
            <ListItem>MySQL, PostgreSQL, MongoDB</ListItem>
            <ListItem>Figma, Notion, Miro</ListItem>
            <ListItem>PyTorch</ListItem>
            <ListItem>etc..</ListItem>
          </UList>
        </AboutDescription>
      </AboutMeContainer>
      <CenteredContainer>
        <GatsbyImage sx={{ height: '100%', width: '100%' }} />
      </CenteredContainer>
    </ContentContainer>
  </SectionContainer>
)

export default About
